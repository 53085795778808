import React, { Fragment } from "react";
import { graphql } from "gatsby";
import { useStaticQuery } from "gatsby";
import { Container, Row, Col } from "react-bootstrap";
import SiteFooterMap from "../components/siteFooterMap";
import HomepageBanner from "../components/homepageBanner";
import SectionOne from "../components/sectionOne";
import SectionTwo from "../components/SectionTwo";
import SectionThree from "../components/sectionThree";
import SectionFour from "../components/sectionFour";
import SectionFive from "../components/sectionFive";

const IndustryDayEN = () => {
  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            bannerMenuEN {
              bannerContent {
                bannerButtonText
                bannerbuttonLink
                description
                image
                title
              }
              menuItems {
                menuItem
                link
              }
            }
          }
        }
      }
    `
  );
  
  const { site } = data;
  return (
    <Fragment>
      <HomepageBanner
        homeBannerContent={site.siteMetadata.bannerMenuEN.bannerContent}
        menuItems={site.siteMetadata.bannerMenuEN.menuItems}
      />
      <Container className={"content"}>
        <Row>
          <Col sm={12}>
          <section id={"SectionOne"}>
          <SectionOne />
            </section>
            <section id={"SectionTwo"}>
            <SectionTwo />
            </section>
            <section id={"SectionThree"}>
            <SectionThree />
            </section>
            <section id={"SectionFour"}>
            <SectionFour />
            </section>
            <section id={"SectionFive"}>
            <SectionFive />
            </section>
          </Col>
        </Row>
      </Container>
      <SiteFooterMap menuItems={site.siteMetadata.bannerMenuEN.menuItems} displayMap ={false}/>
    
    </Fragment>
  );
};

export default IndustryDayEN;
