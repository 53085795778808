import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import TextSection from "../templates/textSection";

const SectionFour = () => {
  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            industrydayENSectionFour {
              sectionFour {
                title
                text
              }
            }
          }
        }
      }
    `
  );

  const siteContent = data.site.siteMetadata.industrydayENSectionFour;
  const sectionFour = siteContent.sectionFour;
  return <TextSection data={sectionFour} />;
};

export default SectionFour;
