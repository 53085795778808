import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import TextSection from "../templates/textSection";

const SectionOne = () => {
  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            industrydayENSectionOne {
              sectionOne {
                title
                text
              }
            }
          }
        }
      }
    `
  );

  const siteContent = data.site.siteMetadata.industrydayENSectionOne;
  const sectionOne = siteContent.sectionOne;
  return <TextSection data={sectionOne} />;
};

export default SectionOne;
