import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import TextSection from "../templates/textSection";

const SectionFive = () => {
  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            industrydayENSectionFive {
              sectionFive {
                title
                text
              }
            }
          }
        }
      }
    `
  );

  const siteContent = data.site.siteMetadata.industrydayENSectionFive;
  const sectionFive = siteContent.sectionFive;
  return <TextSection data={sectionFive} />;
};

export default SectionFive;
