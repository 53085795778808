import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import TextSection from "../templates/textSection";

const SectionThree = () => {
  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            industrydayENSectionThree {
              sectionThree {
                title
                text
              }
            }
          }
        }
      }
    `
  );

  const siteContent = data.site.siteMetadata.industrydayENSectionThree;
  const sectionThree = siteContent.sectionThree;
  return <TextSection data={sectionThree} />;
};

export default SectionThree;
